.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-inline {
  display: inline-flex;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.flex-col {
  flex-direction: column;
}

.underline {
  text-decoration: underline !important;
}

.hidden {
  display: none;
}

.text-align {
  text-align: left;
}

.text-align {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-width {
  width: 100%;
}

.line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}
